import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
  Button,
  Divider,
} from '@mui/material';
import CommunityPage from "./CommunityPage";
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../components/UserContext';
import "../styles/styles.css";
import "../styles/tokens.css";
import api from '../api';
import SocialMediaShare from '../components/SocialMediaShare';

const SI_Page = () => {
  const { id } = useParams();
  const { userData } = useUser();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  
  useEffect(() => {
    const fetchSIPage = async () => {
      try {
        const response = await api.get(`/api/pages/${id}`);
        setPost(response.data.page);
        console.log(response.data.page);
        setLoading(false);
      } catch (error) {
        setError("Error fetching SI Resource");
        setLoading(false);
      }
    };
    
    fetchSIPage();
  }, [id]);
  
  const handleEditSI = () => {
    navigate(`/edit-si/${id}`);
  };
  
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  
  const handleDeleteSI = async () => {
    try {
      await api.delete(`/api/pages/${id}`);
      setSnackbarMessage('SI Resource has been deleted successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      
      setTimeout(() => {
        navigate('/SI');
      }, 1200);
    } catch (error) {
      setSnackbarMessage('Error deleting SI Resource!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };
  
  if (loading) {
    return (
      <Container sx={{ paddingTop: '80px', color: 'white' }}>
        <CircularProgress color="inherit" />
      </Container>
    );
  }
  
  if (error) {
    return (
      <Container sx={{ paddingTop: '80px', color: 'white' }}>
        <Typography variant="h4" component="h2">
          {error}
        </Typography>
      </Container>
    );
  }
  
  if (!post) return null;
  
  return (
    <>
      <Box
        sx={{
          paddingTop: '8rem',
          backgroundColor: '#000000',
          color: 'white',
          minHeight: '100vh',
          paddingBottom: '40px'
        }}
      >
        {/* Box for Title and Image */}
        <Container
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 2,
            marginBottom: '5rem',
            flexDirection: { xs: 'column', md: 'row' },
            maxWidth: '100%',
            height: '400px'
          }}
        >
          {/* Title and Category on the Left */}
          <Box sx={{ flex: 1, textAlign: 'left', height: '100%' }}>
            <Typography variant="body2" sx={{ color: 'gray', marginBottom: '0.7rem' }}>
              SI Resource
            </Typography>

            <Typography
              variant="h4"
              component="h1"
              sx={{
                color: '#AF69ED',
                fontWeight: 'bold',
                fontSize: '2.5rem',
                marginBottom: '4rem',
              }}
            >
              {post.title}
            </Typography>

            {/* Author and Date */}
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              By {post.author || "Author Name"}
            </Typography>
            <Typography variant="body2" sx={{ color: 'gray', marginBottom: '2rem' }}>
              {new Date(post.date).toLocaleDateString()} • {post.estimatedTime} min read
            </Typography>

            <SocialMediaShare/>
          </Box>

          {/* Image on the Right */}
          <Box sx={{ flex: 1, maxWidth: '50%', maxHeight: '100%', display: 'flex', justifyContent: 'center' }}>
            <img
              src={post.image || '/default-image.jpg'}
              alt={post.title}
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: '100%',
                objectFit: 'cover',
                borderRadius: '10px',
              }}
            />
          </Box>
        </Container>

        {/* Main Content */}
        <Container sx={{ textAlign: 'left', width: "75%"}}>
          {/* Render the HTML content with custom styles */}
          <Typography
            variant="body1"
            component="div"
            dangerouslySetInnerHTML={{ __html: post.content }}
            sx={{
              color: '#FFFFFF', // Brighter white color for main text
              lineHeight: '1.8',
              fontSize: '1.1rem',
              '& h1, & h2, & h3, & h4, & h5, & h6': {
                color: '#FFFFFF', // Bright white for headers
                fontWeight: 'bold',
              },
              '& p': {
                color: '#E0E0E0', // Slightly softer white for paragraph text
              },
              '& blockquote': {
                color: '#BBBBBB', // Softer gray for blockquotes
                fontStyle: 'italic',
                borderLeft: '3px solid #555',
                paddingLeft: '1rem',
              },
              '& strong': {
                color: '#FFFFFF', // Bright white for bold text
              },
            }}
          />


          {/* Author Info and Additional Links */}
          <Divider sx={{ borderColor: 'gray', marginY: 2 }} />
          {/* Social Media and Tag Section */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2rem' }}>
            <SocialMediaShare />

            {/* Tag on the Right */}
            {post.category && (
              <Box
                sx={{
                  backgroundColor: '#FFC107',  
                  padding: '10px 20px',    
                  borderRadius: '8px', 
                  color: '#000000',
                  fontWeight: 'bold', 
                  fontSize: '1rem',
                  display: 'inline-block',
                }}
              >
                {post.category}
              </Box>
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: '2rem', marginBottom: '2rem'}}>
                By: {post.author || "Full Name"}
              </Typography>
            </Box>
          </Box>

          {userData && userData.role === 'admin' && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: 2 }}>
              <Button variant="contained" color="primary" onClick={handleEditSI}> 
                Edit SI Resource
              </Button>
              <Button variant="contained" sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'darkred' } }} onClick={handleDeleteSI}>
                Delete SI Resource
              </Button>
            </Box>
          )}

          <CommunityPage pageId={id} title={"Community"} />
        </Container>
      </Box>

      {/* Snackbar component for showing feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SI_Page;
