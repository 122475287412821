// src/assessment/Assessment.js
import React, { useState, useEffect } from 'react';
import api from '../../api';
import { Box, Typography, Container, LinearProgress, Alert, TextField } from '@mui/material';
import { sections, scoreMapping } from "./AssessmentQuestions";

import AssessmentSection from './AssessmentSection';
import FormNavigation from './FormNavigation';
import Recommendations from './Recommendations';
import ResultComponent from './ResultComponent';
import CommunityPage from '../../pages/CommunityPage';

import "../../styles/styles.css";
import "../../styles/tokens.css";
import "../../styles/Assessment.css"

const Assessment = () => {
  const [answers, setAnswers] = useState({});
  const [currentSection, setCurrentSection] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [sectionScores, setSectionScores] = useState([]);
  const [overallScore, setOverallScore] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    const savedSubmitted = localStorage.getItem('assessmentSubmitted');
    const savedResult = localStorage.getItem('assessmentResult');
    if (savedSubmitted === 'true' && savedResult) {
      const parsedResult = JSON.parse(savedResult);
      setFirstName(parsedResult.firstname);
      setLastName(parsedResult.lastname);
      setSubmitted(true);
      setSectionScores(parsedResult.sectionScores);
      setOverallScore(parsedResult.overallScore);
    }
  }, []);

  const handleRetakeAssessment = () => {
    localStorage.removeItem('assessmentSubmitted');
    localStorage.removeItem('assessmentResult');
    setAnswers({});
    setCurrentSection(0);
    setSubmitted(false);
    setError(false);
    setSectionScores([]);
    setOverallScore(0);
    setFirstName("");
    setLastName("");
  };

  const calculateSectionScores = () => {  
    const sectionScores = sections.map((section, index) => {
      const sectionAnswers = answers[index] || {};
      const totalScore = Object.values(sectionAnswers).reduce((acc, item) => {
        const answerScore = scoreMapping[item.type][item.answer] || 0;
        return acc + answerScore;
      }, 0);

      return { section: section.title, totalScore };
    });

    const overallTotalScore = sectionScores.reduce((total, section) => total + section.totalScore, 0);
    const calculatedResult = overallTotalScore < -5
      ? "Strong survival instinct; high resistance to change."
      : overallTotalScore > 5
        ? "Low survival instinct; open to change."
        : "Moderate survival instinct; some resistance to change.";

    return { sectionScores, overallTotalScore, calculatedResult };
  };

  const findNextUnansweredQuestion = () => {
    const currentAnswers = answers[currentSection] || {};
    return sections[currentSection]?.questions.findIndex(
      (q) => currentAnswers[q.question] === undefined
    );
  };

  const handleAnswer = (sectionIndex, question, answer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [sectionIndex]: {
        ...prevAnswers[sectionIndex],
        [question]: { answer, question, type: sections[sectionIndex].questions.find(q => q.question === question).type },
      },
    }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    const currentAnswers = answers[currentSection] || {};
    const allAnswered = sections[currentSection]?.questions.every(
      (q) => currentAnswers[q.question] !== undefined
    );

    if (!allAnswered) {
      setError(true);
    } else {
      setError(false);
      if (currentSection < sections.length - 1) {
        setCurrentSection((prevSection) => prevSection + 1);
      }
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    setError(false);
    if (currentSection > 0) {
      setCurrentSection((prevSection) => prevSection - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const allAnswered = sections.every((section, sectionIndex) => {
      const sectionAnswers = answers[sectionIndex] || {};
      return section.questions.every((q) => sectionAnswers[q.question] !== undefined);
    });

    if (!allAnswered || !firstName || !lastName) {
      setError(!allAnswered);
      return;
    }

    const { sectionScores, overallTotalScore, calculatedResult } = calculateSectionScores();
    setSectionScores(sectionScores);
    setOverallScore(overallTotalScore);

    const transformedAnswers = sections.map((section, sectionIndex) => ({
      section: section.title,
      questions: Object.entries(answers[sectionIndex] || {}).map(([question, answer]) => ({
        question,
        answer: answer.answer,
        type: answer.type
      })),
      sectionScore: sectionScores[sectionIndex].totalScore
    }));

    localStorage.setItem('assessmentSubmitted', true);
    localStorage.setItem('assessmentResult', JSON.stringify({
      firstname: firstName,
      lastname: lastName,
      sectionScores,
      overallScore: overallTotalScore,
      result: calculatedResult
    }));

    try {
      const Name = firstName + " " + lastName;
      const response = await api.post('/api/assessment/submit', {
        userId: '60d0fe4f5311236168a109ca',
        name: Name,
        answers: transformedAnswers,
        totalScore: overallTotalScore,
        Result: calculatedResult
      });

      if (response.status === 201) {
        setSubmitted(true);
        window.scrollTo(0, 0);
      } else {
        console.error('Error submitting assessment:', response.data);
      }
    } catch (error) {
      console.error('Error submitting assessment:', error);
    }
  };

  const calculateProgress = () => {
    const totalQuestions = sections.reduce((sum, section) => sum + section.questions.length, 0);
    const answeredQuestions = Object.values(answers).reduce(
      (sum, sectionAnswers) => sum + Object.keys(sectionAnswers).length,
      0
    );
    return (answeredQuestions / totalQuestions) * 100;
  };

  return (
    <Box sx={{ backgroundColor: "#0000", minHeight: "100vh", py: 4, mt: 4 }}>
      {submitted ? (
        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%'}}>
          <ResultComponent overallScore={overallScore} firstName={firstName} handleRetakeAssessment={handleRetakeAssessment} />
          <Recommendations sectionScores={sectionScores} />
          <Container maxWidth="md" sx={{ color: "black", p: { xs: 2, md: 4 }, borderRadius: 2, boxShadow: 3, marginBottom: '1rem' }}>
            <CommunityPage pageId={'66b06656aee43157a89b29e3'} title={"Feedback and Questions"} />
          </Container>
        </Box>
      ) : (
        <Box>
          <Container className="assessment-container">
            <Typography className="assessment-heading" sx={{ fontWeight: 'bold' }}>Survival Instinct Awareness Assessment (SIAA)</Typography>
            <Typography className="assessment-text">
              Welcome to the Survival Instinct Awareness Assessment. This survey aims to help you understand how your instinctual need to protect and defend your self-identity can influence your creativity, adaptability, and overall personal growth. By identifying these instincts, you can become more aware of your behaviors and thought processes, allowing you to move from a stagnant identity to a more open and fluid flow identity.
            </Typography>
            <Typography className="assessment-text">
              The assessment includes multiple choice questions designed to prompt honest reflection and awareness. Answer each question using a scale of 1 to 5, where:
            </Typography>

            <Box className="assessment-score" sx={{ ml: 2 }}>
              <Typography variant="body1" sx={{ mb: 1 }}>- 1 = Strongly Disagree</Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>- 2 = Somewhat Disagree</Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>- 3 = Neither Disagree or Agree</Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>- 4 = Somewhat Agree</Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>- 5 = Strongly Agree</Typography>
            </Box>
          </Container>

          <Container className="assessment-container">
            <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
              <Typography className="assessment-heading" sx={{ fontWeight: 'bold' }}>
                Survival Instinct Awareness Assessment
              </Typography>

              {/* Progress Bar */}
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                <LinearProgress variant="determinate" value={calculateProgress()} sx={{ flexGrow: 1, mr: 2 }} />
                <Typography variant="body1" className="assessment-text">
                  {`${Math.round(calculateProgress())}%`}
                </Typography>
              </Box>

              {/* Alert for unanswered questions */}
              {error && (
                <Alert severity="error" className="assessment-alert">
                  Please answer all the questions before proceeding to the next section.
                </Alert>
              )}

              {/* Assessment Section */}
              <AssessmentSection
                section={sections[currentSection]}
                answers={answers}
                handleAnswer={handleAnswer}
                currentSection={currentSection}
                activeQuestionIndex={findNextUnansweredQuestion()}
              />

              {/* Name Input Fields - Only show on last section */}
              {currentSection === sections.length - 1 && (
                <>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="assessment-input"
                    error={!firstName}
                    helperText={!firstName ? "First name is required" : ""}
                  />
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="assessment-input"
                    error={!lastName}
                    helperText={!lastName ? "Last name is required" : ""}
                  />
                </>
              )}

              {/* Navigation Buttons */}
              <FormNavigation
                currentSection={currentSection}
                handleNext={handleNext}
                handleBack={handleBack}
                isLastSection={currentSection === sections.length - 1}
                sections={sections}
              />
            </form>
          </Container>
        </Box>
      )}
    </Box>
  );
};

export default Assessment;
