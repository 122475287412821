// api/jsx

import axios from "axios";

// const localURL = "http://localhost:8000";
// const localURL = "http://synergistic-intelligence-node-ap-env.eba-a4biprw9.us-east-1.elasticbeanstalk.com";
const localURL = "https://api.synergisticintelligence.com/";

const api = axios.create({
  baseURL: localURL,
});

export default api;
