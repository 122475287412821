import React from 'react';
import {
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

export default function SocialMediaShare(){
    return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        Share This Post
      </Typography>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <IconButton
          sx={{
            backgroundColor: 'white',
            borderRadius: '50%',
            padding: '8px',
            color: '#000000',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              backgroundColor: '#e0e0e0',
              transform: 'scale(1.1)',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <LinkedInIcon />
        </IconButton>
        <IconButton
          sx={{
            backgroundColor: 'white',
            borderRadius: '50%',
            padding: '8px',
            color: '#000000',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              backgroundColor: '#e0e0e0',
              transform: 'scale(1.1)',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <FacebookIcon />
        </IconButton>
        <IconButton
          sx={{
            backgroundColor: 'white',
            borderRadius: '50%',
            padding: '8px',
            color: '#000000',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              backgroundColor: '#e0e0e0',
              transform: 'scale(1.1)',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <TwitterIcon />
        </IconButton>
        <IconButton
          sx={{
            backgroundColor: 'white',
            borderRadius: '50%',
            padding: '8px',
            color: '#000000',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              backgroundColor: '#e0e0e0',
              transform: 'scale(1.1)',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <ShareIcon />
        </IconButton>
      </Box>
    </Box>
  );
}