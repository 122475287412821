import React, { useState, useEffect } from "react";
import { Card, CardContent, CardMedia, CardActionArea, Typography, Grid, Box, Container, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import "../styles/styles.css";
import "../styles/tokens.css";
import api from "../api";

const Hero = () => (
  <section style={styles.hero}>
    <div style={styles.heroContent}>
      <div style={styles.leftContent}>
        <p style={styles.smallText}>Explore</p>
        <h1 style={styles.h1}>Methodologies at Synergistic Intelligence</h1>
      </div>
      <div style={styles.rightContent}>
        <p style={styles.p}>
          Welcome to our Resource Overview, where you can find comprehensive educational articles designed to enhance your knowledge. Each section provides valuable insights and easy navigation to help you access the information you need.
        </p>
        <div style={styles.buttonContainer}>
          <button style={styles.learnButton} onClick={() => window.location.href = '/about'}>Learn More</button>
          <button style={styles.signupButton} onClick={() => window.location.href = '/signup'}>Sign Up</button>
        </div>
      </div>
    </div>
  </section>
);

const ResourceCard = ({ image, alttext, title, description }) => (
  <Card style={{ backgroundColor: "black" }}>
    <CardActionArea sx={{ "&:hover": { backgroundColor: '#212121' } }}>
      <CardMedia
        component="img"
        alt={alttext}
        height="275"
        image={image}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ color: '#a259ff', fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography variant="body" sx={{ color: 'white' }} component="div">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);

const SI = () => {
  const [cardsToShow, setCardsToShow] = useState(4);
  const [showButton, setShowButton] = useState(true);
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await api.get('/api/pages');
        const data = response.data.pages;
        setPages(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching pages:", error);
        setLoading(false);
      }
    };
    fetchPages();
  }, []);

  if (loading) {
    return (
      <Container sx={{ paddingTop: '80px', color: 'white' }}>
        <CircularProgress color="inherit" />
      </Container>
    );
  }

  const handleViewAllClick = () => {
    setCardsToShow(pages.length); // Show all pages
    setShowButton(false); 
  };

  return (
    <Box sx={{ flexGrow: 1, py: 3, px: 5 }}>
      <Hero />
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 1, textAlign: 'center' }}>
        Explore the Resources
      </Typography>
      <Grid container spacing={3} sx={{ px: 7 }}>
        {pages.length > 0 ? (
          pages.slice(0, cardsToShow).map((page, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Link to={`/si-page/${page._id}`} style={{ textDecoration: 'none' }}>
                <ResourceCard
                  image={page.image || "placeholder.jpg"} 
                  alttext={page.title}
                  title={page.title}
                />
              </Link>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" sx={{fontSize: '5rem', color: 'white', textAlign: 'center', width: '100%', marginTop: '2rem' }}>
            No SI resources available.
          </Typography>
        )}
      </Grid>
      <div style={styles.centeredButton}>
        {showButton && pages.length > 0 &&
          <button style={styles.viewallButton} onClick={handleViewAllClick}>
            View All
          </button>
        }
      </div>
    </Box>
  );
};

const styles = {
  hero: {
    backgroundColor: '#000',
    padding: '3rem 0',
  },
  heroContent: {
    width: '95%',
    maxWidth: '85rem',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftContent: {
    flex: 1,
    marginRight: '2.5rem',
  },
  rightContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  smallText: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#ffcc00',
    margin: 0,
    textAlign: 'left',
  },
  h1: {
    fontSize: '2.7rem',
    fontWeight: 'bold',
    color: '#a259ff',
    margin: 0,
    textAlign: 'left',
  },
  p: {
    fontSize: '1rem',
    color: 'white',
    lineHeight: '1.6',
    marginBottom: '1.25rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
  },
  learnButton: {
    padding: '0.7rem 1.5rem',
    backgroundColor: 'white',
    border: 'none',
    borderRadius: '0.3125rem',
    color: 'black',
    fontSize: '1rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  signupButton: {
    padding: '0.7rem 1.5rem',
    backgroundColor: 'transparent',
    border: '0.125rem solid white',
    borderRadius: '0.3125rem',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'border-color 0.3s, color 0.3s',
  },
  centeredButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '2rem',
  },
  viewallButton: {
    padding: '0.7rem 1.5rem',
    backgroundColor: 'transparent',
    border: '0.125rem solid white',
    borderRadius: '0.3125rem',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'border-color 0.3s, color 0.3s',
  },
};

export default SI;
