import React from "react";
import { Box, Typography } from "@mui/material";
import "../styles/styles.css";
import "../styles/tokens.css";

export default function About() {
  return (
    <Box>
      {/* Relative parent container for positioning */}
      <Box
        sx={{
          position: "relative",
          paddingBottom: "150px", // Ensure space for the footer
        }}
      >
        {/* Background Ellipse */}
        <img
          src={process.env.PUBLIC_URL + "/images/Ellipse.png"}
          alt="Ellipse"
          style={{
            position: "absolute",
            top: "-350px",
            left: "-10%",
            width: "auto",
            zIndex: -1,
          }}
        />

        {/* Section 1: Title */}
        <Box
          p={2}
          sx={{
            position: "relative",
            textAlign: "center",
            paddingTop: "200px", // Offset for background image
          }}
        >
          <Typography variant="h3" className="title" sx={{ fontSize: "48px" }}>
            What is Synergistic Intelligence?
          </Typography>
        </Box>

        {/* Section 2: Description */}
        <Box
          p={2}
          sx={{
            position: "relative",
            top: "30px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "55%",
            textAlign: "center",
            marginBottom: "60px", // Space before next section
          }}
        >
          <Typography className="text">
            At Synergistic Intelligence, we specialize in fostering an
            environment where the sum is truly greater than its parts. Drawing
            inspiration from the groundbreaking concepts in “The Fifth
            Discipline” by Peter Senge and David Bohm’s “On Dialogue,” we have
            developed a unique approach that leverages the emergent property of
            group intelligence.
          </Typography>
        </Box>

        {/* Section 3: Innovation Section */}
        <Box
          sx={{
            position: "relative",
            backgroundColor: "white",
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "25px",
            padding: "60px 20px",
            marginTop: "100px",
            textAlign: "left",
          }}
        >
          <Typography
            component="h2"
            className="pop"
            sx={{
              fontWeight: "700",
              fontSize: "30px",
              marginBottom: "1rem",
              textAlign: "center",
            }}
          >
            Holistic, transcendent innovation.
          </Typography>

          <Typography
            className="text"
            sx={{
              fontSize: "1rem",
              color: "black",
              margin: "0 auto",
              maxWidth: "80%",
            }}
          >
            Our methodology is centered on creating an atmosphere of open
            communication, shared understanding, and mutual respect, crucial for
            nurturing what we call ‘Synergistic Intelligence.’ This is not just
            about pooling individual knowledge; it’s about creating something
            greater – a collective cognitive capability that transcends
            individual capacities.
            <br />
            <br />
            Imagine your team not just sharing ideas, but collectively evolving
            them. With our guidance, teams learn to think together in a
            coordinated and aligned manner, tapping into a holistic and
            transcendent form of innovation.
          </Typography>
        </Box>

        {/* Section 4: Call to Action */}
        <Box
          sx={{
            position: "relative",
            backgroundColor: "var(--primary-color)",
            color: "white",
            padding: "50px 20px",
            textAlign: "center",
            marginTop: "50px",
          }}
        >
          <Box
            sx={{
              margin: "0 auto",
              padding: "20px",
              border: "5px solid var(--accent-color)",
              borderRadius: "20px",
              maxWidth: "80%",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.5rem",
                textAlign: "center",
              }}
            >
              Choose Synergistic Intelligence, and let’s embark on a journey to
              transform your team’s collaborative potential into holistic,
              transcendent innovation. Let’s redefine what’s possible together.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
