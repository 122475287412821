import React, { useState, useEffect } from 'react';
import { useUser } from '../UserContext';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Box, Container, Toolbar, Typography, Button } from '@mui/material';
import DropdownMenu from './DropdownMenu';
import UserAuthData from "../UserAuthData";
import SignOutButton from './SignOutButton';
import pages from './NavigationPages';
import HamburgerNavBar from './HamburgerNavBar';
import '../../styles/styles.css';
import '../../styles/tokens.css';

const buttonStyle = { mx: 2 };

export default function NavigationBar() {
  const { setUserData } = useUser();
  const { userData, isLoading } = UserAuthData();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleOpenMenu = (event, menuName) => {
    setAnchorEl(event.currentTarget);
    setCurrentMenu(menuName);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCurrentMenu(null);
  };

  const renderButton = (page) => (
    <Button
      key={page.name}
      onClick={page.link ? null : (event) => handleOpenMenu(event, page.name)}
      sx={buttonStyle}
    >
      <Typography
        className='nav-text'
        sx={{
          color:
            location.pathname === (page.link || '/editable-page')
              ? 'var(--accent-color)'
              : 'var(--body-text-color)',
          fontWeight: location.pathname === (page.link || '/editable-page') ? 550 : 400,
          fontSize: '0.875rem',
          margin: '0 10px',
          '&:hover': { color: 'var(--accent-color)' },
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {page.link ? (
          <Link to={page.link} style={{ textDecoration: 'none', color: 'inherit' }}>
            {page.name}
          </Link>
        ) : (
          page.name
        )}
      </Typography>
    </Button>
  );

  useEffect(() => {
    if (userData) {
      setUserData(userData); // Store userData in Context
    }
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize); // eslint-disable-next-line 
  }, []); 

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const filteredPages = userData?.role?.includes('admin') || userData?.role?.includes('company')
    ? pages
    : pages.filter((page) => page.name !== 'Mentors');

  return (
    <AppBar position='fixed' elevation={0} style={{ backgroundColor: 'black' }}>
      <Container maxWidth={false}>
        <Toolbar
          disableGutters
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          {isMobile ? (
            <>
              {/* Mobile view with HamburgerNavBar on the left, centered logo, and Sign In on the right */}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <HamburgerNavBar />
              </Box>
              <Link to='/' style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                <img
                  src={process.env.PUBLIC_URL + '/logo76.png'}
                  alt='Logo'
                  className='logo'
                  style={{ marginRight: '10px' }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    lineHeight: 1.2,
                    color: 'inherit',
                  }}
                >
                  <Typography className='nav-text' sx={{ fontSize: '1rem' }}>
                    Synergistic
                  </Typography>
                  <Typography className='nav-text' sx={{ fontSize: '1rem' }}>
                    Intelligence
                  </Typography>
                </Box>
              </Link>
              {!userData && (
                <Button sx={buttonStyle}>
                  <Link to='/login' style={{ textDecoration: 'none' }} className='link'>
                    <Typography className='nav-text' sx={{ fontWeight: 300 }}>
                      Sign In
                    </Typography>
                  </Link>
                </Button>
              )}
            </>
          ) : (
            <>
              {/* Desktop view with full navigation, including buttons */}
              <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
                <Link to='/' style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                  <img
                    src={process.env.PUBLIC_URL + '/logo76.png'}
                    alt='Logo'
                    className='logo'
                    style={{ marginRight: '10px' }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      lineHeight: 1.2,
                      color: 'inherit',
                    }}
                  >
                    <Typography className='nav-text' sx={{ fontSize: '1rem' }}>
                      Synergistic
                    </Typography>
                    <Typography className='nav-text' sx={{ fontSize: '1rem' }}>
                      Intelligence
                    </Typography>
                  </Box>
                </Link>
              </Box>

              <Box display='flex' alignItems='center' sx={{ flexGrow: 1, justifyContent: 'space-around', overflow: 'hidden' }}>
                {filteredPages.map(renderButton)}
                {currentMenu && (
                  <DropdownMenu
                    anchorEl={anchorEl}
                    handleCloseMenu={handleCloseMenu}
                    items={filteredPages.find((page) => page.name === currentMenu).items}
                  />
                )}
              </Box>

              {userData ? (
                <Box sx={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}>
                  <Button sx={buttonStyle}>
                    <Link to='/dashboard' style={{ textDecoration: 'none' }} className='link'>
                      <Typography className='nav-text' sx={{ fontWeight: 300 }}>
                        Dashboard
                      </Typography>
                    </Link>
                  </Button>
                  <SignOutButton />
                </Box>
              ) : (
                <Box sx={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}>
                  <Button sx={buttonStyle}>
                    <Link to='/login' style={{ textDecoration: 'none' }} className='link'>
                      <Typography className='nav-text' sx={{ fontWeight: 300 }}>
                        Sign In
                      </Typography>
                    </Link>
                  </Button>
                </Box>
              )}
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
