import React from "react";
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import { Facebook, Instagram, LinkedIn, YouTube } from "@mui/icons-material";
import XIcon from "@mui/icons-material/X";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "black",
        color: "white",
        paddingY: 4,
        textAlign: "left",
        position: "relative",
      }}
    >
      <Grid
        container
        spacing={4}
        sx={{ justifyContent: "left", mb: 10, ml: 5 }}
      >
        <Grid item xs={12} sm={2}>
          <Box sx={{ alignItems: "left", justifyContent: "left" }}>
            <Link
              to="/"
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/logo76.png"}
                alt="Logo"
                className="logo"
                style={{ marginRight: "10px" }}
              />
              <Box>
                <Typography className="nav-text" sx={{ fontSize: "1rem" }}>
                  Synergistic
                </Typography>
                <Typography className="nav-text" sx={{ fontSize: "1rem" }}>
                  Intelligence
                </Typography>
              </Box>
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
            Quick Links
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Typography
              component={Link} to="/"
              sx={{
                display: "block",
                color: "white",
                textDecoration: "none",
              }}
            >
              Home Page
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              component={Link}
              to="/about"
              sx={{
                display: "block",
                color: "white",
                textDecoration: "none",
              }}
            >
              About Us
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              component={Link}
              to="/contact"
              sx={{
                display: "block",
                color: "white",
                textDecoration: "none",
              }}
            >
              Contact Us
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              component={Link}
              to="/blog"
              sx={{
                display: "block",
                color: "white",
                textDecoration: "none",
              }}
            >
              Blog Posts
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              component={Link}
              to="/faqs"
              sx={{
                display: "block",
                color: "white",
                textDecoration: "none",
              }}
            >
              FAQs
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
            Resources
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Typography
              component={Link}
              to="/guides"
              sx={{
                display: "block",
                color: "white",
                textDecoration: "none",
              }}
            >
              Guides
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              component={Link}
              to="/case-studies"
              sx={{
                display: "block",
                color: "white",
                textDecoration: "none",
              }}
            >
              Case Studies
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              component={Link}
              to="/webinars"
              sx={{
                display: "block",
                color: "white",
                textDecoration: "none",
              }}
            >
              Webinars
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              component={Link}
              to="/ebooks"
              sx={{
                display: "block",
                color: "white",
                textDecoration: "none",
              }}
            >
              E-books
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              component={Link}
              to="/templates"
              sx={{
                display: "block",
                color: "white",
                textDecoration: "none",
              }}
            >
              Templates
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
            Stay Connected
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Typography
              component="a"
              href="https://substack.com/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "block",
                color: "white",
                textDecoration: "none",
              }}
            >
              Substack Articles
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              component="a"
              href="https://twitter.com/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "block",
                color: "white",
                textDecoration: "none",
              }}
            >
              Twitter Feed
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              component="a"
              href="https://www.linkedin.com/company/synergistic-intelligence/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "block",
                color: "white",
                textDecoration: "none",
              }}
            >
              LinkedIn Profile
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              component="a"
              href="https://instagram.com/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "block",
                color: "white",
                textDecoration: "none",
              }}
            >
              Instagram Gallery
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              component="a"
              href="https://youtube.com/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "block",
                color: "white",
                textDecoration: "none",
              }}
            >
              YouTube Channel
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, mr: 15 }}>
            Subscribe
          </Typography>
          <Typography sx={{ mb: 2, fontSize: "1.1rem" }}>
            Join our newsletter to stay updated on features and releases.
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            <TextField
              placeholder="Your Email Here"
              variant="outlined"
              size="small"
              sx={{
                backgroundColor: "transparent",
                borderRadius: 2,
                color: "white",
                width: "550px",
                height: "45px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                input: {
                  color: "white",
                  fontSize: "1.1rem",
                  padding: "15px",
                },
              }}
            />
            <Button
              variant="outlined"
              sx={{
                borderColor: "white",
                mt: 1,
                paddingX: 5,
                paddingY: 1.5,
                "&:hover": {
                  borderColor: "white",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "1.2rem",
                  textTransform: "none",
                  mr: 1,
                  ml: 1,
                }}
              >
                Subscribe
              </Typography>
            </Button>
          </Box>
          <Typography sx={{ mt: 1.5, fontSize: "0.8rem" }}>
            By subscribing, you agree to our Privacy Policy and consent to receive updates.
          </Typography>
        </Grid>
      </Grid>

      <Divider
        sx={{
          borderColor: "purple",
          marginBottom: 4,
          marginLeft: 6,
          marginRight: 6,
        }}
      />

      <Grid container spacing={4} sx={{ justifyContent: "center" }}>
        <Grid item>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <IconButton
              href="https://facebook.com"
              target="_blank"
              aria-label="Facebook"
            >
              <Facebook sx={{ color: "white", fontSize: 32 }} />
            </IconButton>
            <IconButton
              href="https://instagram.com"
              target="_blank"
              aria-label="Instagram"
            >
              <Instagram sx={{ color: "white", fontSize: 32 }} />
            </IconButton>
            <IconButton
              href="https://x.com/"
              target="_blank"
              aria-label="XIcon"
            >
              <XIcon sx={{ color: "white", fontSize: 32 }} />
            </IconButton>
            <IconButton
              href="https://www.linkedin.com/company/synergistic-intelligence/"
              target="_blank"
              aria-label="LinkedIn"
            >
              <LinkedIn sx={{ color: "white", fontSize: 32 }} />
            </IconButton>
            <IconButton
              href="https://youtube.com"
              target="_blank"
              aria-label="YouTube"
            >
              <YouTube sx={{ color: "white", fontSize: 32 }} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
