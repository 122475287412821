import React from 'react';

const InsightComponent = ({ title, text, linkText, href }) => {
  return (
    <section style={styles.insightSection}>
      <div style={styles.iconWrapper}>
        <img src={process.env.PUBLIC_URL + '/images/Frame.svg'} alt="Icon" style={styles.icon} />
      </div>
      <h3 style={styles.insightTitle}>{title}</h3>
      <p style={styles.insightDescription}>{text}</p>
      <a href={href} style={styles.insightLink}>
        {linkText} <span style={styles.arrow}>›</span>
      </a>
    </section>
  );
};

const styles = {
  insightSection: {
    backgroundColor: '#0000',
    padding: '0', 
    borderRadius: '0.75rem', 
    maxWidth: '22rem', 
    color: 'white',
    boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.3)', 
    textAlign: 'left',
    fontFamily: 'Poppins, sans-serif',
    position: 'relative',
  },
  iconWrapper: {
    position: 'absolute',
    top: '1rem', 
  },
  icon: {
    width: '1.5rem', 
    height: '1.5rem',
    color: '#a259ff',
  },
  insightTitle: {
    fontSize: '1.5rem', 
    fontWeight: '600',
    marginTop: '3.75rem', 
    marginBottom: '0.625rem',
    lineHeight: '1.4',
    color: 'white',
  },
  insightDescription: {
    fontSize: '1rem',
    color: '#b0b0b0',
    marginBottom: '1.25rem',
    lineHeight: '1.5',
  },
  insightLink: {
    color: '#a259ff',
    fontSize: '1rem', 
    fontWeight: 'bold',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    fontSize: '1.25rem', 
    marginLeft: '0.3125rem', 
  },
};

export default InsightComponent;
