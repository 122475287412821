import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Paper, Box, Button, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../components/UserContext';
import "../styles/styles.css";
import "../styles/tokens.css";
import api from '../api';

const BlogPage = () => {
    const { userData } = useUser();
    const [loading, setLoading] = useState(true);
    const [blogs, setBlogs] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('View all');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await api.get('/api/blogs');
                const data = response.data.blogs;
                setBlogs(data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching blogs:", error);
                setLoading(false);
            }
        };
        fetchBlogs();
    }, []);

    if (loading) {
        return (
            <Container sx={{ paddingTop: '80px', color: 'white' }}>
                <CircularProgress color="inherit" />
            </Container>
        );
    }

    const handleCreateBlog = () => {
        navigate('/create-blog');
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    // Filter blogs based on the selected category
    const filteredBlogs = selectedCategory === 'View all'
        ? blogs
        : blogs.filter((blog) => blog.category === selectedCategory);

    return (
        <>
            <Box sx={{ paddingTop: '8rem', backgroundColor: '#000000', minHeight: '100vh', paddingBottom: '2.5rem', color: 'white' }}>
                <Container sx={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
                    <Box sx={{ textAlign: 'left', marginBottom: '1rem' }}>
                        <Typography variant="subtitle2" sx={{ color: 'gray' }}>Blog</Typography>
                        <Typography variant="h2" component="h1" sx={{ fontWeight: 'bold', color: 'var(--accent-color)' }}>
                            Synergistic Intelligence Blogs
                        </Typography>
                        <Typography variant="subtitle1" sx={{ color: '#FFC107', marginTop: '0.5rem', marginBottom: '4.5rem' }}> 
                            Unlock Your True Potential
                        </Typography>
                    </Box>
{/* 
                    <Box sx={{ width: '100%', textAlign: 'left', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1rem', marginBottom: '2.5rem' }}>
                        {userData && (
                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={handleCreateBlog}
                                sx={{ marginBottom: '1.5rem' }}
                            >
                                Create Blog
                            </Button>
                        )}
                    </Box> */}

                    {/* Featured Blog Section */}
                    <Grid container spacing={4} sx={{ marginBottom: '2rem' }} alignItems="center">
                        {/* Featured Blog Section */}
                        {blogs[0] && (
                            <Link 
                                to={`/blog/${blogs[0]._id}`} 
                                style={{ textDecoration: 'none' }}
                            >
                                <Grid 
                                    container 
                                    spacing={4} 
                                    sx={{ 
                                        marginBottom: '2rem', 
                                        cursor: 'pointer', 
                                        transition: 'transform 0.3s, box-shadow 0.3s', 
                                        '&:hover': { 
                                            transform: 'scale(1.02)', 
                                            boxShadow: '0 0.5rem 1.5rem rgba(0,0,0,0.3)' 
                                        },
                                        alignItems: 'center', // Center align content vertically
                                        paddingLeft: '1.5rem', 
                                    }}
                                >
                                    <Grid item xs={12} md={6}>
                                        <img 
                                            src={blogs[0].image} 
                                            alt={blogs[0].title} 
                                            style={{ 
                                                width: '100%', 
                                                height: 'auto', 
                                                maxWidth: '35vw', 
                                                maxHeight: '55vh', 
                                                borderRadius: '0.625rem', 
                                                marginRight: '1.5rem' 
                                            }} 
                                        />
                                    </Grid>
                                    <Grid 
                                        item 
                                        xs={12} 
                                        md={6} 
                                        sx={{ 
                                            display: 'flex', 
                                            flexDirection: 'column', 
                                            justifyContent: 'center', // Center the text vertically
                                            paddingLeft: '1.5rem' 
                                        }}
                                    >
                                        <Typography variant="subtitle1" sx={{ color: 'gray' }}>{blogs[0].category}</Typography>
                                        <Typography variant="h4" component="h2" sx={{ color: '#FFFFFF', fontWeight: 'bold', marginBottom: '1rem' }}>
                                            {blogs[0].title}
                                        </Typography>
                                        <Typography variant="body1" sx={{ color: '#FFFFFF', marginBottom: '1rem' }}>
                                            {blogs[0].content.slice(0, 100) + "..."}
                                        </Typography>
                                        <Typography variant="caption" sx={{ color: 'gray' }}>
                                            {blogs[0].author} • {new Date(blogs[0].date).toLocaleDateString()} • 5 min read
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Link>
                        )}
                    </Grid>

                                        {/* Category Filter Buttons */}
                                        <Box sx={{ 
                        display: 'flex', 
                        flexWrap: 'wrap', 
                        gap: '1rem', 
                        marginBottom: '2rem',
                        justifyContent: 'space-between'
                    }}>
                        <Box sx={{ 
                            display: 'flex', 
                            gap: '1rem',
                            flexWrap: 'wrap'
                        }}>
                            {["View all", "Abundance", "Education", "Innovation", "Letting Go", "Tools for Awaking", "Thriving with AI"].map((category, index) => (
                                <Button
                                    key={index}
                                    variant={selectedCategory === category ? "contained" : "outlined"}
                                    sx={{ 
                                        color: '#FFFFFF', 
                                        borderColor: '#FFFFFF',
                                        marginBottom: '0.5rem'
                                    }}
                                    onClick={() => handleCategoryClick(category)}
                                >
                                    {category}
                                </Button>
                            ))}
                        </Box>
                        {userData && (
                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={handleCreateBlog}
                            >
                                Create Blog
                            </Button>
                        )}
                    </Box>

                    {/* Blog Cards */}
                    <Grid container spacing={4} sx={{ marginTop: '2rem' }}>
                        {filteredBlogs.map((post) => (
                            <Grid item key={post._id} xs={12} sm={6} md={4} sx={{ marginBottom: '3.5rem' }}>
                                <Link to={`/blog/${post._id}`} style={{ textDecoration: 'none' }}>
                                    <Paper
                                        elevation={3}
                                        sx={{
                                            padding: '1.5rem',
                                            backgroundColor: '#1a1a1a',
                                            color: '#FFFFFF',
                                            height: '100%',
                                            borderRadius: '0.625rem',
                                            transition: 'transform 0.3s, box-shadow 0.3s',
                                            '&:hover': {
                                                transform: 'translateY(-0.5rem)',
                                                boxShadow: '0 0.5rem 1rem rgba(0,0,0,0.2)',
                                            },
                                        }}
                                    >
                                        {/* Image Section */}
                                        <Box
                                            sx={{
                                                width: '100%',
                                                height: '12rem',
                                                overflow: 'hidden',
                                                borderRadius: '0.625rem',
                                                marginBottom: '1rem',
                                            }}
                                        >
                                            <img
                                                src={post.image}
                                                alt={post.title}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    borderRadius: '0.625rem',
                                                }}
                                            />
                                        </Box>

                                        {/* Category */}
                                        <Typography variant="subtitle2" sx={{ color: 'gray', marginBottom: '0.5rem' }}>
                                            {post.category || 'Category'}
                                        </Typography>

                                        {/* Title */}
                                        <Typography
                                            variant="h6"
                                            component="h3"
                                            sx={{ fontWeight: 'bold', color: 'var(--accent-color)', marginBottom: '0.5rem' }}
                                        >
                                            {post.title}
                                        </Typography>

                                        {/* Excerpt */}
                                        <Typography variant="body2" sx={{ color: '#cccccc', marginBottom: '1rem' }}>
                                            {post.excerpt}
                                        </Typography>

                                        {/* Author and Date */}
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                            <Box>
                                                <Typography variant="caption" sx={{ color: '#FFFFFF' }}>
                                                    {post.author || 'Author Name'}
                                                </Typography>
                                                <Typography variant="caption" sx={{ color: 'gray', display: 'block' }}>
                                                    {new Date(post.date).toLocaleDateString()} • {post.estimatedTime} min read
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </Link>
                            </Grid>
                        ))}
                        {filteredBlogs.length === 0 && (
                            <Typography variant="body1" sx={{ color: 'white', marginTop: '2rem' }}>No blog posts available for this category.</Typography>
                        )}
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default BlogPage;
