import React, { useState } from "react";
import { Card, CardContent, CardMedia, CardActionArea, Typography, Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";
import "../../styles/styles.css";
import "../../styles/tokens.css";

const sections = [
  {
    image: "/images/stagnant_identity.jpeg",
    alttext: "einstein image",
    title: "Understanding Stagnant Identity",
    description: '"The Fifth Discipline" by Peter Senge to understand how to foster a learning organization and embrace change.',
    link: "/editable-page/SI/Understanding Stagnant Identity",
  },
  {
    image: "/images/survival_inst.jpeg",
    alttext: "defender image",
    title: "Exploring Survival Instincts",
    description: '“Presence” by Otto Scharmer, Peter Senge, Joseph Jaworski, and Betty Sue Flowers to understand how true, open collaboration can bring about benevolent, transformative change.',
    link: "/editable-page/SI/Exploring Survival Instincts",
  },
  {
    image: "/images/defender.png",
    alttext: "explorer image",
    title: "Recognizing Ego Defensiveness",
    description: 'Chapter 11 “The Fifth Discipline” Team Learning',
    link: "/editable-page/SI/Recognizing Ego Defensiveness",
  },
  {
    image: "/images/flow_ident.jpeg",
    alttext: "growth image",
    title: "Shifting Towards Flow Identity",
    description: '“Mindset” by Carol Dweck to explore how we grow with the understanding that we are malleable.',
    link: "/editable-page/SI/Shifting Towards Flow Identity",
  },
  {
    image: "/images/cultivating_openess.jpeg",
    alttext: "home image",
    title: "Cultivating Openness and Awareness",
    description: '“The Wisdom of Insecurity” by Alan Watts to understand the importance of letting go and risk-taking.',
    link: "/editable-page/SI/Cultivating Openness and Awareness",
  },
  {
    image: "/images/deep_reflection.jpeg",
    alttext: "innovator image",
    title: "Deeper Personal Reflection",
    description: '“The Ten Thousand Things” by Robert Saltzman to understand what it means to be completely free to be yourself.',
    link: "/editable-page/SI/Deeper Personal Reflection",
  },
];

const ResourceCard = ({image, alttext, title, description}) => (
  <Card style={{ backgroundColor: "black" }}>
    <CardActionArea sx={{"&:hover": {backgroundColor: '#212121'}}}>
      <CardMedia
        component="img"
        alt={alttext}
        height="275"
        image={image}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ color: '#a259ff', fontWeight: 'bold'}}>
          {title}
        </Typography>
        <Typography variant="body" sx={{ color: 'white' }}>
          {description}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);

const AdditionalAssessment = () => {
  const [cardsToShow, setCardsToShow] = useState(4);
  const [showButton, setShowButton] = useState(true);

  const handleViewAllClick = () => {
    setCardsToShow(sections.length);
    setShowButton(false);
  };

  return (
    <Box sx={{ flexGrow: 1, py: 3, px: 5 }}>
      <Typography variant="h5" sx={{fontWeight: 'bold', marginBottom: 1, fontSize: '2.5rem', textAlign: 'center', marginTop: '6rem' }}>
        Explore the Assessment Resources
      </Typography>
      <Grid container spacing={3} sx={{px: 7}}>
        {sections.slice(0, cardsToShow).map((section, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Link to={section.link} style={{ textDecoration: 'none' }}>
              <ResourceCard 
              image={section.image}
              alttext={section.alttext}
              title={section.title}
              description={section.description}>
              </ResourceCard>
            </Link>
          </Grid>
        ))}
      </Grid>
      <div style={styles.centeredButton}>
        {showButton && 
        <button style={styles.viewallButton} onClick={handleViewAllClick}>
          View All
        </button>
        }
      </div>
    </Box>
  );
};

const styles = {
  hero: {
    backgroundColor: '#000',
    padding: '3rem 0', 
  },
  heroContent: {
    width: '95%',
    maxWidth: '85rem', 
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftContent: {
    flex: 1,
    marginRight: '2.5rem', 
  },
  rightContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  smallText: {
    fontSize: '1rem', 
    fontWeight: 'bold',
    color: '#ffcc00',
    margin: 0,
    textAlign: 'left',
  },
  h1: {
    fontSize: '2.7rem', 
    fontWeight: 'bold',
    color: '#a259ff',
    margin: 0,
    textAlign: 'left',
  },
  p: {
    fontSize: '1rem',
    color: 'white',
    lineHeight: '1.6',
    marginBottom: '1.25rem', 
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
  },
  learnButton: {
    padding: '0.7rem 1.5rem', 
    backgroundColor: 'white',
    border: 'none',
    borderRadius: '0.3125rem', 
    color: 'black',
    fontSize: '1rem', 
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  signupButton: {
    padding: '0.7rem 1.5rem', 
    backgroundColor: 'transparent',
    border: '0.125rem solid white',
    borderRadius: '0.3125rem',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'border-color 0.3s, color 0.3s',
  },
  centeredButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  viewallButton: {
    padding: '0.7rem 1.5rem', 
    backgroundColor: 'transparent',
    border: '0.125rem solid white',
    borderRadius: '0.3125rem',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'border-color 0.3s, color 0.3s',
  },
};

export default AdditionalAssessment;
