import React from 'react';
import InsightComponent from '../components/InsightComponent';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  return (
    <div style={styles.app}>
      <Header />
      <Hero />
      <Cards />
      <Features />
    </div>
  );
};

const Header = () => (
  <header style={styles.header}>
    <video
      src={process.env.PUBLIC_URL + '/Animation.mp4'}
      style={styles.video}
      autoPlay
      loop
      muted
      playsInline
    />
  </header>
);

const Hero = () => {
  const navigate = useNavigate();

  const handleAssessmentClick = () => {
    navigate('/assessment');
  };

  const handleLearnMoreClick = () => {
    navigate('/about');
  };

  return (
    <section style={styles.hero}>
      <div style={styles.heroContent}>
        {/* Left side - Heading */}
        <div style={styles.leftContent}>
          <h1 style={styles.h1}>Unlock Your Potential Today</h1>
        </div>

        {/* Right side - Paragraph + Buttons side by side */}
        <div style={styles.rightContent}>
          <p style={styles.p}>
            Discover how our tailored assessment can elevate yourself. 
            Take the first step towards transformation and unlock valuable insights.
          </p>
          <div style={styles.buttonContainer}>
            <button style={styles.assessButton} onClick={handleAssessmentClick}>Assessment</button>
            <button style={styles.learnButton} onClick={handleLearnMoreClick}>Learn More</button>
          </div>
        </div>
      </div>
    </section>
  );
};

const Cards = () => (
  <section style={styles.cardsSection}>
    <h2 style={styles.cardsTitle}>Drive Team Growth with Insights and <br/> Actionable Strategies</h2>
    <div style={styles.cardContainer}>
      <InsightComponent
        title="Transform Insights into Actionable Strategies for Team Success"
        text="Our assessment provides a clear roadmap to enhance team performance and collaboration."
        linkText="Start"
        link="/assessment"
      />
      <InsightComponent
        icon="🔲"
        title="Step-by-Step: How to Leverage Your Assessment Results Effectively"
        text="Begin by taking the assessment, then analyze the results to identify strengths and areas for improvement."
        linkText="Analyze"
        link="/dashboard"
      />
      <InsightComponent
        icon="🔲"
        title="Real Success Stories: Hear from Teams Who Transformed Their Dynamics"
        text="Discover how our assessment has empowered teams to achieve remarkable results."
        linkText="Inspire"
        link="/blog"
      />
    </div>
  </section>
);

const Features = () => (
  <section style={styles.features}>
    <div style={styles.featureText}>
      <h2 style={styles.featureTitle}>Unlock Hidden Strengths and Foster Team Success</h2>
      <p style={styles.featureDescription}>
        Our assessment identifies strengths and areas for growth within your team.
        By leveraging these insights, you can foster collaboration and drive performance.
      </p>
      <div style={styles.highlights}>
        <div style={styles.highlight}>
          <h3 style={styles.boost}>Boost</h3>
          <p>Teamwork and productivity through targeted strategies.</p>
        </div>
        <div style={styles.highlight}>
          <h3 style={styles.elevate}>Elevate</h3>
          <p>Your team's performance with actionable insights.</p>
        </div>
      </div>
    </div>
    <img src={process.env.PUBLIC_URL + '/images/brilliant.png'} alt="Inspiration" style={styles.featureImage} />
  </section>
);


const styles = {
  app: {
    backgroundColor: '#000',
    color: 'white',
    fontFamily: 'Poppins, sans-serif',
    margin: 0,
    padding: 0,
    marginTop: '6rem',
  },
  header: {
    position: 'relative',
    width: '100%',
    height: '50vh',
    overflow: 'hidden',
  },
  video: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: 'auto',
    minHeight: '100%',
    transform: 'translate(-50%, -50%)',
    objectFit: 'cover',
  },
  hero: {
    backgroundColor: '#000',
    padding: '5rem 0', 
  },
  heroContent: {
    width: '80%',
    maxWidth: '75rem', 
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftContent: {
    flex: 1,
    marginRight: '3rem', 
  },
  rightContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  h1: {
    fontSize: '3rem', 
    fontWeight: 'bold',
    color: 'white',
    margin: 0,
    textAlign: 'left',
  },
  p: {
    fontSize: '1.125rem',
    color: '#b0b0b0',
    lineHeight: '1.6',
    marginBottom: '1.25rem', 
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
  },
  assessButton: {
    padding: '0.75rem 1.5rem', 
    backgroundColor: '#a259ff',
    border: 'none',
    borderRadius: '0.3125rem', 
    color: 'white',
    fontSize: '1rem', 
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  learnButton: {
    padding: '0.75rem 1.5rem', 
    backgroundColor: 'transparent',
    border: '0.125rem solid #a259ff',
    borderRadius: '0.3125rem',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'border-color 0.3s, color 0.3s',
  },
  cardsSection: {
    backgroundColor: '#000',
    padding: '3.75rem 0',
    marginTop: '3rem'
  },
  cardsTitle: {
    fontSize: '2.5rem', 
    fontWeight: '600',
    color: 'white',
    marginTop: '1%',
    textAlign: 'left',
    width: '80%',
    maxWidth: '75rem',
    margin: '3% auto 4% auto',
  },
  cardContainer: {
    width: '80%',
    maxWidth: '75rem', 
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1.25rem', 
  },
  features: {
    width: '80%', 
    maxWidth: '75rem',
    marginTop: '6rem',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    padding: '3.125rem 0',
    backgroundColor: '#000',
    alignItems: 'center',
    gap: '2rem',
  },
  featureText: {
    flex: 1,
    textAlign: 'left',       
  },
  featureTitle: {
    fontSize: '2.5rem',    
    fontWeight: '600',
    color: 'white',
    marginBottom: '1rem',
  },
  featureDescription: {
    fontSize: '1.125rem',  
    color: '#b0b0b0',
    lineHeight: '1.6',
    marginBottom: '2rem',
  },
  highlights: {
    display: 'flex',
    gap: '1.5rem',
    marginTop: '1.25rem', 
  },
  highlight: {
    flex: 1,
  },
  boost: {
    fontSize: '1.5rem',       
    color: '#a259ff',          
    marginBottom: '0.5rem',
  },
  elevate: {
    fontSize: '1.5rem',       
    color: '#f1c40f',         
    marginBottom: '0.5rem',
  },
  featureImage: {
    flex: 1,
    maxWidth: '100%',         
    height: 'auto',
    borderRadius: '0.5rem', 
  },
};

export default HomePage;
