import React from 'react';
import { Outlet } from 'react-router-dom';
import NavigationBar from './NavBar/NavigationBar';
import Footer from './Footer'; 
import { Box } from '@mui/material';

const Layout = () => {
    return (
        <Box 
            sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh"
            }}
        >
            <NavigationBar />
            <Box sx={{ flexGrow: 1 }}>
                <Outlet />
            </Box>
            <Footer />
        </Box>
    );
};

export default Layout;
