import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Paper, Divider } from "@mui/material";
import EditableTextBox from "../components/EditableTextBox";
import { useUser } from "../components/UserContext";
import CommunityPage from "./CommunityPage";
import api from "../api";
import "../styles/styles.css";
import "../styles/tokens.css";

export default function EditablePage() {
  const { tab, title } = useParams();
  const { userData } = useUser();
  const [pageData, setPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPageData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get(`/api/pages/${tab}-${title}`);
        const data = await response.data;
        setPageData(data.page);
      } catch (error) {
        console.error("Error fetching page data:", error);
        setPageData(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPageData();
  }, [tab, title]);

  const handleUpdate = async (updatedContent) => {
    try {
      const response = await api.patch(`/api/pages/${tab}-${title}`, {
        summary: updatedContent,
      });
      setPageData(response.data.page);
    } catch (error) {
      console.error("Error updating page data:", error);
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        p="2rem"
        sx={{
          margin: "auto",
          width: "70%",
          paddingTop: "6.25rem",
        }}
      >
        <Paper elevation={3} sx={{ padding: "2rem", width: "100%" }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ textAlign: "center", color: 'black', marginBottom: "2rem" }}
          >
            {title}
          </Typography>
          <Divider />
          <Box sx={{ marginTop: "2rem", textAlign: "justify" }}>
            <EditableTextBox
              initialContent={pageData?.summary || "Page Data Not Found"}
              isAdmin={userData?.role === "admin" || false}
              onSave={handleUpdate}
            />
          </Box>
        </Paper>
        <Box sx={{ marginTop: "2rem" }}>
          <CommunityPage pageId={pageData?._id} title={"Community"} />
        </Box>
      </Box>
    </Box>
  );
}
