// Editor.js
import React, { useMemo, useRef, useImperativeHandle, forwardRef, useState } from 'react';
import YooptaEditor, { createYooptaEditor } from '@yoopta/editor';
import { html } from '@yoopta/exports';
import Paragraph from '@yoopta/paragraph';
import Blockquote from '@yoopta/blockquote';
import Embed from '@yoopta/embed';
import Image from '@yoopta/image';
import Link from '@yoopta/link';
import Callout from '@yoopta/callout';
import Accordion from '@yoopta/accordion';
import { NumberedList, BulletedList, TodoList } from '@yoopta/lists';
import { Bold, Italic, CodeMark, Underline, Strike, Highlight } from '@yoopta/marks';
import { HeadingOne, HeadingThree, HeadingTwo } from '@yoopta/headings';
import Code from '@yoopta/code';
import Table from '@yoopta/table';
import Divider from '@yoopta/divider';
import ActionMenuList, { DefaultActionMenuRender } from '@yoopta/action-menu-list';
import Toolbar, { DefaultToolbarRender } from '@yoopta/toolbar';
import LinkTool, { DefaultLinkToolRender } from '@yoopta/link-tool';
import api from '../api';

// Define all plugins for the Yoopta editor
const plugins = [
  Paragraph,
  Table,
  Divider.extend({
    elementProps: {
      divider: (props) => ({
        ...props,
        color: '#007aff',
      }),
    },
  }),
  Accordion,
  HeadingOne,
  HeadingTwo,
  HeadingThree,
  Blockquote,
  Callout,
  NumberedList,
  BulletedList,
  TodoList,
  Code,
  Link,
  Embed,
  Image.extend({
    options: {
      async onUpload(file) {
        try {
          console.log("UPLOADING IMAGE");
          const formData = new FormData();
          formData.append('image', file);
          
          const response = await api.post('/api/uploads', formData);
          
          return {
            src: response.data.imageUrl,
            alt: file.name,
            sizes: {
              width: 800,
              height: 600,
            },
          };
        } catch (error) {
          console.error('Error uploading image:', error);
          throw error;
        }
      },

      async onDelete(src) {
        try {
          console.log("DELETING IMAGE");
          const fileName = src.split('/').pop();
          await api.delete(`/api/uploads/${fileName}`);
        } catch (error) {
          console.error('Error deleting image:', error);
          throw error;
        }
      }
    },
  }),
];

const TOOLS = {
  ActionMenu: {
    render: DefaultActionMenuRender,
    tool: ActionMenuList,
  },
  Toolbar: {
    render: DefaultToolbarRender,
    tool: Toolbar,
  },
  LinkTool: {
    render: DefaultLinkToolRender,
    tool: LinkTool,
  },
};

const MARKS = [Bold, Italic, CodeMark, Underline, Strike, Highlight];

// ForwardRef component to expose functions to parent component
const Editor = forwardRef((props, ref) => {
  const editor = useMemo(() => createYooptaEditor(), []);
  const selectionRef = useRef(null);

  // Internal state to manage editor content
  const [content, setContent] = useState(null);

  // Update internal content state when editor content changes
  const handleEditorChange = () => {
    const currentContent = editor.getEditorValue();
    setContent(currentContent);
  };

  // Function to convert the editor's content to markdown
  const getMarkdownContent = () => {
    return content ? html.serialize(editor, content) : '';
  };

  // Expose getMarkdownContent to parent component via ref
  useImperativeHandle(ref, () => ({
    getMarkdownContent,
  }));

  return (
    <div ref={selectionRef} style={{ width: '100%' }}>
      <YooptaEditor
        editor={editor}
        plugins={plugins}
        tools={TOOLS}
        marks={MARKS}
        selectionBoxRoot={selectionRef}
        onChange={handleEditorChange}
        autoFocus
        style={{
          color: '#ffffff',
          backgroundColor: '#000000',
          fontFamily: 'sans-serif',
          lineHeight: '1.6',
          padding: '10px',
          boxSizing: 'border-box',
          width: '100%',
        }}
        elementProps={{
          style: { width: '100%' },
        }}
      />
    </div>
  );
});

export default Editor;
